html, body {
  min-height: 100%;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: border-box;
}

.main-content {
  margin-top: $nav-height;
}