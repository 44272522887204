$font-weight-light: 300 !default;
$font-weight-regular: 400 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-extrabold: 800 !default;

$font-family: 'Open Sans', sans-serif !default;
$font-weight: $font-weight-regular !default;
$font-html-size: 62.5% !default;
$font-line-height: 1.4 !default;

$fixed-font-family: monospace !default;
$fixed-font-size: 85% !default;
$fixed-line-height: $font-line-height !default;

$typo-h1-size-phone: 2.6rem !default;
$typo-h1-size-tablet: 2.6rem !default;
$typo-h1-size-desktop: 4.2rem !default;
$typo-h1-font-line-height-phone: 1.3 !default;
$typo-h1-font-line-height-tablet: 1.1 !default;
$typo-h1-font-line-height-desktop: 1.1 !default;
$typo-h1-font-weight: $font-weight-light !default;
$typo-h1-font-color: $primary-color-1 !default;
$typo-h1-letter-spacing: 1px !default;

$typo-h2-size-phone: 2.2rem !default;
$typo-h2-size-tablet: 2.2rem !default;
$typo-h2-size-desktop: 3.0rem !default;
$typo-h2-font-line-height-phone: 1.3 !default;
$typo-h2-font-line-height-tablet: 1.1 !default;
$typo-h2-font-line-height-desktop: 1.1 !default;
$typo-h2-font-weight: $font-weight-semibold !default;
$typo-h2-font-color: $primary-color-2 !default;
$typo-h2-letter-spacing: 1px !default;

$typo-h3-size-phone: 1.8rem !default;
$typo-h3-size-tablet: 1.8rem !default;
$typo-h3-size-desktop: 2.2rem !default;
$typo-h3-font-line-height-phone: 1.3 !default;
$typo-h3-font-line-height-tablet: 1.5 !default;
$typo-h3-font-line-height-desktop: 1.5 !default;
$typo-h3-font-weight: $font-weight-semibold !default;
$typo-h3-font-color: $grey-7 !default;
$typo-h3-letter-spacing: 1px !default;

$typo-h4-size-phone: 1.5rem !default;
$typo-h4-size-tablet: 1.5rem !default;
$typo-h4-size-desktop: 1.8rem !default;
$typo-h4-font-line-height-phone: 1.5 !default;
$typo-h4-font-line-height-tablet: 1.6 !default;
$typo-h4-font-line-height-desktop: 1.6 !default;
$typo-h4-font-weight: $font-weight-bold !default;
$typo-h4-font-color: $grey-7 !default;
$typo-h4-letter-spacing: 1px !default;

$typo-h5-size-phone: 1.3rem !default;
$typo-h5-size-tablet: 1.3rem !default;
$typo-h5-size-desktop: 1.5rem !default;
$typo-h5-font-line-height-phone: 1.5 !default;
$typo-h5-font-line-height-tablet: 1.5 !default;
$typo-h5-font-line-height-desktop: 1.5 !default;
$typo-h5-font-weight: $font-weight-regular !default;
$typo-h5-font-color: $grey-7 !default;
$typo-h5-letter-spacing: 1px !default;

$typo-h6-size-phone: 2rem !default;
$typo-h6-size-tablet: 2rem !default;
$typo-h6-size-desktop: 2.2rem !default;
$typo-h6-font-line-height-phone: 1.4 !default;
$typo-h6-font-line-height-tablet: 1.5 !default;
$typo-h6-font-line-height-desktop: 1.5 !default;
$typo-h6-font-weight: $font-weight-regular !default;
$typo-h6-font-color: $primary-color-2 !default;
$typo-h6-letter-spacing: 1px !default;

$typo-h7-size-phone: 1.3rem !default;
$typo-h7-size-tablet: 1.3rem !default;
$typo-h7-size-desktop: 1.5rem !default;
$typo-h7-font-line-height-phone: 1.5 !default;
$typo-h7-font-line-height-tablet: 1.5 !default;
$typo-h7-font-line-height-desktop: 1.5 !default;
$typo-h7-font-weight: $font-weight-extrabold !default;
$typo-h7-letter-spacing: 0.7px !default;
$typo-h7-font-color: $primary-color-3 !default;
$typo-h7-letter-spacing: 1px;

$typo-p-size-phone: 1.5rem !default;
$typo-p-size-tablet: 1.5rem !default;
$typo-p-size-desktop: 1.8rem !default;
$typo-p-font-line-height-phone: 1.5 !default;
$typo-p-font-line-height-tablet: 1.6 !default;
$typo-p-font-line-height-desktop: 1.6 !default;
$typo-p-font-weight: $font-weight-regular !default;
$typo-p-font-color: $grey-7 !default;
$typo-p-letter-spacing: 1px !default;

$typo-lead-size-phone: 1.7rem !default;
$typo-lead-size-tablet: 1.7rem !default;
$typo-lead-size-desktop: 2.2rem !default;
$typo-lead-font-line-height-phone: 1.6 !default;
$typo-lead-font-line-height-tablet: 1.6 !default;
$typo-lead-font-line-height-desktop: 1.8 !default;
$typo-lead-font-weight: $font-weight-light !default;
$typo-lead-font-color: $grey-7 !default;
$typo-lead-letter-spacing: 1px !default;