$bs-red: #941e35;
$bs-red-light: #A31D38;
$bs-blue: #3D7499;
$bs-blue-light: #D9E1F0;
$bs-grey: #EDF0F2;

$bs-white: #ffffff;
$bs-black: #000000;

$primary-color-1: $bs-red;
$primary-color-2: $bs-blue;
$primary-color-3: rgba(163, 29, 56, 0.8);
$secondary-color-4-fill: rgba(163, 29, 56, 0.8);

$grey-1: $bs-white;
