$button-radius: 3px !default;

$button-padding-top: 8px;
$button-padding-side: 8px;
$button-small-padding-top: 8px;
$button-small-padding-side: 8px;

$button-font-size: 1.8rem !default;
$button-color: $grey-1 !default;
$button-color-change-amount: 10% !default;
$button-transition-changes-darkens: true !default;
$button-transition-duration: 0.25s !default;
$button-transition-function: cubic-bezier(0.445, 0.050, 0.550, 0.950) !default;
$button-dropshadow-active: true !default;
$button-dropshadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25) !default;
$button-dropshadow-hover: 0 1px 1px 0 rgba(0, 0, 0, 0.25) !default;
$button-dropshadow-active: 0 0 0 0 rgba(0, 0, 0, 0.25) !default;
