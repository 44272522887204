.anchor {
  position: absolute;

  top: -1 * $nav-height;
}

@include bp(desktop) {
  .anchor {
    top: -1 * $nav-height-desktop;
  }
}