@media all and (-ms-high-contrast: none), all and (-ms-high-contrast: active) {
  section.two-columns,
  section.three-columns {
    .main {
      .columns {
        .column {
          width: 100%;
        }
      }
    }
  }
}

@media all and (min-width: 1200px) and (-ms-high-contrast: none), all and (min-width: 1200px) and (-ms-high-contrast: active) {
  .element-list {
    .element {
      padding-right: 55px;
    }
  }

  .top {
    .main {
      .logo-container {
        flex: 1 1 0%;
      }
    }
  }
}

