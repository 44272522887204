@import "../modules/all";
@import "maps.scss";

@each $number in map-keys($primary-colors) {
  $color: map-get($primary-colors, $number);
  .#{$number}-background {
    background-color: $color;
  }
  .#{$number}-font {
    color: $color !important;
    & > * {
      @extend .#{$number}-font;
    }
  }
}

@for $number from 1 through $secondary-color-count {
  $color: map-get($secondary-colors, "secondary-#{$number}-fill");
  .secondary-#{$number}-background {
    background-color: $color;
  }
  .secondary-#{$number}-border {
    border: 1px solid map-get($secondary-colors, "secondary-#{$number}-border");
  }
  .secondary-#{$number}-complete {
    @extend .secondary-#{$number}-background;
    @extend .secondary-#{$number}-border;
  }
  .secondary-#{$number}-font {
    color: $color !important;

    & > * {
      @extend .secondary-#{$number}-font;
    }
  }
}

@each $number in map-keys($grey-colors) {
  $color: map-get($grey-colors, $number);
  .#{$number}-background {
    background-color: $color;
  }
  .#{$number}-font {
    color: $color !important;

    & > * {
      @extend .#{$number}-font;
    }
  }
}
