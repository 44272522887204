a {
  text-decoration: underline;
  color: $link-color;
  cursor: pointer;

  &:hover {
    color: $primary-color-1;
  }

  &.prominent {
    font-size: $link-size-prominent;
    font-weight: $font-weight-bold;
  }
}
