@use "sass:math";

@import "../../modules/all.scss";
@import "../../partials/breakpoints.scss";

section {
  position: relative;
  display: flex;
  justify-content: center;

  .main {
    width: 100%;
    max-width: $layouting-content-max-width;

    z-index: 100;

    @include content-padding;

    @include bp(tablet) {
      .columns {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }

    @each $key in map-keys($title-section-spacings) {
      * + .columns {
        @include bp($key) {
          padding-top: map-get($title-section-spacings, $key);
        }
      }
    }
  }
}

section.two-columns {
  .main {
    .columns {
      @include bp(phone) {
        & > .column:not(:first-child) {
          margin-top: $section-padding-phone;
        }
      }

      @include bp(tablet) {
        flex: 0 1 100%;
        flex-direction: row;
        justify-content: space-between;

        & > .column {
          width: 100%;
          max-width: calc(50% - #{math.div($section-padding-tablet, 2)});
        }

        & > .column:not(:first-child) {
          margin-top: 0;
        }
      }

      @include bp(desktop) {
        & > .column {
          max-width: calc(50% - #{math.div($section-padding-desktop, 2)});
        }
      }
    }
  }
}

section.three-columns {
  @extend section, .two-columns;
  .main {
    .columns {
      @include bp(tablet) {
        flex-wrap: wrap;

        & > .column {
          width: 100%;
          min-width: calc(50% - #{math.div($section-padding-tablet, 2)});
        }

        & > .column:nth-child(3) {
          margin-top: $section-padding-tablet;
        }
      }

      @include bp(desktop) {
        & > .column {
          min-width: 0;
          max-width: calc(33.333% - #{math.div($section-padding-desktop, 3)});
        }

        & > .column:nth-child(3) {
          margin-top: 0;
        }
      }
    }
  }
}

section.background-image.one-column {
  background: url(https://cdn1.vox-cdn.com/thumbor/RlNBoJAjAmtv_apb3YyOcujiIYk=/cdn0.vox-cdn.com/uploads/chorus_asset/file/3800880/dark_souls_dancer.0.jpg) no-repeat center center;
  background-size: cover;

  align-items: center;
  text-align: center;

  @include bp(tablet) {
    min-height: $section-background-image-min-height;
  }

  @include bp(desktop) {
    min-height: $section-background-image-min-height-desktop;
  }
}

section.background-image::before {
  content: '';

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 0;
}

section.background-image.background-lighten::before {
  opacity: 0.8;
  background-color: $grey-1;
}

section.background-image.background-darken::before {
  opacity: 0.4;
  background-color: $grey-7;
}

section.background-image.two-columns, section.background-image.three-columns {
  background: url(http://wallpapercave.com/wp/PncNvN3.jpg) no-repeat center center;
  background-size: cover;
  align-items: center;

  @include bp(tablet) {
    background-size: cover;
    padding: 0;
    min-height: $section-background-image-min-height;

    .columns > .column {
      @include box-shadow;
      padding: $section-padding-tablet;
    }
  }
}

.padding {
  padding: $section-padding-phone;

  @include bp(tablet) {
    padding: $section-padding-tablet;
  }
  @include bp(desktop) {
    padding: $section-padding-desktop;
  }
}

.no-padding {
  padding: 0 !important;
}
