.element-list {
  width: 100%;
  max-width: 100%;
  margin-bottom: 40px;

  word-wrap: break-word;

  h2 {
    margin-bottom: 10px;
  }

  .element {
    width: 100%;
    margin-bottom: 3px;

    padding: 20px 24px;

    .name {
      margin-bottom: 6px;
    }
  }
}

@include bp(tablet) {
  .element-list {
    .element {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .name {
        flex: 0 0 50%;

        margin-bottom: 0;
      }

      .url {
        display: flex;

        align-items: center;
        justify-content: flex-end;

        text-align: right;
      }
    }

    .element--reference {
      .name {
        flex: 0 0 50%;
      }

      .url {
        flex: 0 0 50%;

        min-height: 22px;
        padding-right: 40px;

        background: url('/static/img/braemsteiner/icon-reference-link-2x.png') no-repeat center right;
        background-size: 26px 22px;

        display: block;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .element--document {
      .name {
        flex: 0 0 75%;
      }

      .url {
        flex: 0 0 25%;

        min-height: 26px;
        padding-right: 35px;

        background: url('/static/img/braemsteiner/icon-document-link-2x.png') no-repeat center right;
        background-size: 21px 26px;
      }
    }
  }
}