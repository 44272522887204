$input-font-size: 1.8rem !default;
$input-label-font-size: 1.6rem !default;
$input-label-margin-bottom: 11px !default;
$input-formgroup-margin-bottom: $input-label-margin-bottom !default;
$input-transition-duration: 0.25s !default;
$input-transition-function: cubic-bezier(0.445, 0.050, 0.550, 0.950) !default;
$input-dropshadow-active: true !default;
$input-dropshadow: 0 2px 2px 0 $grey-3 !default;
$input-radius: 6px !default;
$input-border-color: $grey-3 !default;
$input-border: 1px solid $input-border-color !default;
$input-border-color-active: $primary-color-1 !default;
$input-border-size: 2px !default;
$input-border-active: $input-border-size solid $input-border-color-active !default;
$input-border-disabled: 1px solid $grey-4 !default;
$input-height: 54px !default;
$input-vertical-padding: 14px !default;
$input-horizontal-padding: 20px !default;
$textarea-height: 120px !default;
$inline-label-width: calc(60px + #{$input-horizontal-padding}) !default;
