.logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: 100%;

  padding-right: 15px;

  .logo-link {
    display: block;

    .logo {
      display: block;

      width: 100%;
    }
  }
}

.top {
  .main {
    .logo {
      max-height: calc(#{$nav-height} - 8px);
    }
  }
}

@include bp(desktop) {
  .top {
    .main {
      .logo {
        max-height: calc(#{$nav-height-desktop} - 8px);
      }
    }
  }
}