.grid {
  width: 100%;
  max-width: 100%;

  .grid_element {
    display: block;

    margin: 5px 0 65px;
  }

  .grid_element--fake {
    display: none;
  }
}

.grid--shortcut {
  .grid_element {
    text-align: center;
    text-decoration: none;

    .shortcut-image {
      display: block;

      width: 100%;
      max-width: 54px;
      margin: 0 auto 15px;
    }
  }
}

.grid--employee {
  .grid_element {
    text-align: center;

    .employee-portrait-image {
      display: block;

      width: 100%;
      max-width: 100px;
      margin: 0 auto 15px;
    }

    .name {
      margin-bottom: 0;

      font-weight: $font-weight-semibold;
    }

    .email {
      display: block;

      margin-bottom: 25px;

      font-weight: $font-weight-regular;
    }

    .function {
      font-weight: $font-weight-light;

      margin-bottom: 8px;
    }
  }
}

@include bp(tablet) {
  .grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .grid_element {
      width: 30%;
      margin-left: 10px;
      margin-right: 10px;
    }

    .grid_element--fake {
      display: block !important;
    }
  }
}