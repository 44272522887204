.content-vertical {
  & > h1 {
    margin-top: 10px;
  }

  & > h2 {
    margin-top: 20px;
  }

  & > h3, .lead {
    margin-top: 20px;
  }

  & > h4 {
    margin-top: 20px;
  }

  & > h5, .caption {
    margin-top: 10px;
  }

  & > h6, .quote {
    margin-top: 20px;
    width: 90%;
  }

  & > h7, .footnote, .margin {
    margin-top: 20px;
  }

  & > p {
    margin-top: 10px;
  }

  & > img {
    margin-top: 30px;
  }

  & > button {
    margin-top: 20px;
  }

  & > *:first-child {
    margin-top: 0;
  }
}

.content-horizontal {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;
}

@mixin content-padding($tablet: tablet, $desktop: desktop) {
  padding: $section-padding-phone;

  @include bp($tablet) {
    padding: $section-padding-tablet;
  }

  @include bp($desktop) {
    padding: $section-padding-desktop 0;
  }
}
