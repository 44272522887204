.dropdown {
  width: 100%;
  position: relative;
  height: $dropdown-height;
  font-size: $dropdown-font-size;

  &:before {
    position: absolute;
    content: $dropdown-icon;
    right: $dropdown-border-size;
    top: $dropdown-border-size;
    font-size: $dropdown-icon-font-size;
    text-align: center;
    line-height: $dropdown-height;
    font-family: FontAwesome;
    width: calc(#{$dropdown-height} - (#{$dropdown-border-size} * 2));
    height: calc(#{$dropdown-height} - (#{$dropdown-border-size} * 2));
    background-color: $grey-2;
    border-bottom-right-radius: $dropdown-border-radius;
    border-top-right-radius: $dropdown-border-radius;
    border-left: 2px solid $grey-3;
  }

  select {
    @if ($dropdown-dropshadow-active) {
      box-shadow: $dropdown-dropshadow;
    }
    cursor: pointer;
    padding: $dropdown-padding-vertical $dropdown-padding-horizontal;
    height: 100%;
    margin: 0px;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: $grey-4;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    border-radius: $dropdown-border-radius;
  }
}
