$dropdown-border-radius: 6px !default;
$dropdown-border-size: 1px !default;
$dropdown-font-size: 1.8rem !default;
$dropdown-padding-horizontal: 20px !default;
$dropdown-padding-vertical: 12px !default;
$dropdown-dropshadow-active: true !default;
$dropdown-dropshadow: 0 2px 2px 0 $grey-4 !default;
$dropdown-height: 54px !default;
$dropdown-icon-font-size: 24px !default;
$dropdown-icon: "\f107" !default;
$dropdown-option-height: $dropdown-height !default;
