@use "sass:math";

.form-group {
  font-size: $input-font-size;
  &:not(:last-child) {
    margin-bottom: $input-formgroup-margin-bottom;
  }

  label.text {
    font-size: $input-label-font-size;
    font-weight: $font-weight-bold;
    display: block;
    color: $primary-color-1;
    margin-bottom: $input-label-margin-bottom;
    width: 100%;
  }

  label.info {
    display: none;
  }

  input.form-control, textarea.form-control {
    padding: $input-vertical-padding $input-horizontal-padding;
    max-height: $input-height;
    width: 100%;
    border-radius: $input-radius;
    border: $input-border;
    @if ($input-dropshadow-active) {
      box-shadow: $input-dropshadow;
    }
    &:hover {
      transition-timing-function: $input-transition-function;
      transition-duration: $input-transition-duration;
      border: 1px solid $grey-5;
    }
    &:focus, &:active {
      transition-timing-function: $input-transition-function;
      transition-duration: $input-transition-duration;
      border: $input-border-active;
      max-height: $input-height;
      padding: ($input-vertical-padding - math.div($input-border-size, 2)) ($input-horizontal-padding - math.div($input-border-size, 2));
    }
    &[disabled] {
      transition-timing-function: none;
      border: $input-border-disabled;
      background-color: $grey-3;
      color: $grey-5;
    }
  }

  textarea.form-control {
    height: $textarea-height;
    max-height: none;
    &:focus, &:active {
      max-height: none;
    }
  }
}

.form-group.inline {
  position: relative;

  label.text {
    display: inline-block;
    position: absolute;
    height: $input-height;
    line-height: $input-height;
    width: $inline-label-width;
    padding-left: $input-horizontal-padding;
  }

  label.info {
    display: block;
  }

  textarea.form-control, input.form-control {
    padding-left: $inline-label-width;
  }
}

@each $state in $statusArray {
  $color: map-get($statusKeyColors, $state);
  .form-group.inline.#{$state} {
    input.form-control, textarea.form-control {
      border: 1px solid $color;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    label.info {
      padding: $input-vertical-padding $input-horizontal-padding;
      background-color: rgba($color: $color, $alpha: 0.2);
      height: 58px;
      font-weight: $font-weight-semibold;
      box-shadow: 0 2px 0 0 $color;
    }
  }
}

span.info, div.info {
  font-size: $input-label-font-size;
  background-color: rgba($color: $primary-color-1, $alpha: 0.2);
  border: 1px solid $primary-color-1;
  border-radius: $input-radius;
  padding: $input-vertical-padding $input-horizontal-padding;
}
