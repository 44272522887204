@include bp(desktop) {
  .top {
    .menu-item {
      height: 40px !important;

      border-width: 3px !important;

      font-weight: $font-weight-regular;
      font-size: 1.6rem;

      &.active {
        font-weight: $font-weight-semibold;
      }
    }
  }
}
