$nav-centered: false;
$nav-height: 60px !default;
$nav-height-desktop: 80px !default;
$nav-background-color: $grey-1 !default;
$nav-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1) !default;
$nav-font-size-button: 1.6rem !default;
$nav-font-size-mobile: 2.0rem !default;
$nav-font-size-desktop: 1.6rem !default;
$nav-line-height: 1.5 !default;
$nav-side-padding-desktop: 10px;
$nav-side-margin-desktop: 5px;
