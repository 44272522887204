@font-face {
  font-family: Officina Serif ITC Pro;
  font-weight: 600;
  src: url('/static/fonts/officina/30F9C7_0_0.eot');
  src: url('/static/fonts/officina/30F9C7_0_0.eot?#iefix') format('embedded-opentype'), url('/static/fonts/officina/30F9C7_0_0.woff2') format('woff2'), url('/static/fonts/officina/30F9C7_0_0.woff') format('woff'), url('/static/fonts/officina/30F9C7_0_0.ttf') format('truetype');
}

@font-face {
  font-family: Univers LT Pro;
  font-weight: 700;
  src: url('/static/fonts/univers/30F9C8_0_0.eot');
  src: url('/static/fonts/univers/30F9C8_0_0.eot?#iefix') format('embedded-opentype'), url('/static/fonts/univers/30F9C8_0_0.woff2') format('woff2'), url('/static/fonts/univers/30F9C8_0_0.woff') format('woff'), url('/static/fonts/univers/30F9C8_0_0.ttf') format('truetype');
}