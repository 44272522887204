@import "breakpoints";

html {
  font-family: $font-family;
  font-weight: $font-weight;
  font-size: $font-html-size;
  line-height: $font-line-height;

  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

@mixin typo-element($element, $size, $weight, $line-height, $color, $style: normal, $spacing: 1px) {
  #{$element} {
    width: 100%;
    margin: 0;

    color: $color;

    font-weight: $weight;
    font-style: $style;
    letter-spacing: $spacing;

    @each $breakpoint in (phone, tablet, desktop) {
      @include bp($breakpoint) {
        font-size: map-get($map: $size, $key: $breakpoint);
        line-height: map-get($map: $line-height, $key: $breakpoint);
      }
    }
  }
}

@include typo-element("body", $typo-p-sizes, $typo-p-font-weight, $typo-p-line-heights, $typo-p-font-color, normal, $typo-p-letter-spacing);
@include typo-element("p,a,li", $typo-p-sizes, $typo-p-font-weight, $typo-p-line-heights, $typo-p-font-color, normal, $typo-p-letter-spacing);
@include typo-element("p.lead", $typo-lead-sizes, $typo-lead-font-weight, $typo-lead-line-heights, $typo-lead-font-color, normal, $typo-lead-letter-spacing);
@include typo-element(h1, $typo-h1-sizes, $typo-h1-font-weight, $typo-h1-line-heights, $typo-h1-font-color, normal, $typo-h1-letter-spacing);
@include typo-element(h2, $typo-h2-sizes, $typo-h2-font-weight, $typo-h2-line-heights, $typo-h2-font-color, normal, $typo-h2-letter-spacing);
@include typo-element("h3,.lead", $typo-h3-sizes, $typo-h3-font-weight, $typo-h3-line-heights, $typo-h3-font-color, normal, $typo-h3-letter-spacing);
@include typo-element(h4, $typo-h4-sizes, $typo-h4-font-weight, $typo-h4-line-heights, $typo-h4-font-color, normal, $typo-h4-letter-spacing);
@include typo-element("h5,.caption", $typo-h5-sizes, $typo-h5-font-weight, $typo-h5-line-heights, $typo-h5-font-color, normal, $typo-h5-letter-spacing);
@include typo-element("h6,.quote", $typo-h6-sizes, $typo-h6-font-weight, $typo-h6-line-heights, $typo-h6-font-color, italic, $typo-h6-letter-spacing);
@include typo-element("h7,.footnote,.margin", $typo-h7-sizes, $typo-h7-font-weight, $typo-h7-line-heights, $typo-h7-font-color, normal, $typo-h7-letter-spacing);