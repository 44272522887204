$primary-color-count: 5 !default;
$primary-color-1: #4a90e2 !default;
$primary-color-2: #eb8f17 !default;
$primary-color-3: #50e3c2 !default;
$primary-color-4: #9013fe !default;
$primary-color-5: #b8e986 !default;

$secondary-color-count: 9 !default;
$secondary-color-1-fill: #ffffff !default;
$secondary-color-1-border: #f8cb1c !default;
$secondary-color-2-fill: #ffffff !default;
$secondary-color-2-border: #bf3058 !default;
$secondary-color-3-fill: #ffffff !default;
$secondary-color-3-border: #52ca7c !default;
$secondary-color-4-fill: #dfeaf7 !default;
$secondary-color-4-border: #0f5b99 !default;
$secondary-color-5-fill: #dfeaf7 !default;
$secondary-color-5-border: #dfeaf7 !default;
$secondary-color-6-fill: #f8cb1c !default;
$secondary-color-6-border: #f8cb1c !default;
$secondary-color-7-fill: #bf3058 !default;
$secondary-color-7-border: #bf3058 !default;
$secondary-color-8-fill: #52ca7c !default;
$secondary-color-8-border: #52ca7c !default;
$secondary-color-9-fill: #0f5b99 !default;
$secondary-color-9-border: #0f5b99 !default;

$grey-color-count: 7 !default;
$grey-1: #ffffff !default;
$grey-2: #edf0f2 !default;
$grey-3: #dfe3e6 !default;
$grey-4: #c0c7cc !default;
$grey-5: #7b858c !default;
$grey-6: #3f464d !default;
$grey-7: #000000 !default;

@function auto-text-color($color) {
  @if (lightness($color) > 60) {
    @return $grey-6;
  } @else {
    @return $grey-1;
  }
}
