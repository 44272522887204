.web-error {

  .exception {
    margin: $err-margin;

    .title {
      margin: $err-title-margin;
    }

    .message {
      margin: $err-message-margin;
    }

    .link {
      margin: $err-link-margin;
    }
  }
}

@include bp(desktop) {
  .web-error {

    .exception {
      margin: $err-margin-desktop;

      .title {
        margin: $err-title-margin-desktop;
      }

      .message {
        margin: $err-message-margin-desktop
      }

      .link {
        margin: $err-link-margin-desktop;
      }
    }
  }
}