.header {
  display: block;

  .address {
    .address-image-wrapper {
      display: none;
    }
  }
}

.footer {
  background-color: $bs-red;

  color: $bs-white;

  .address-link,
  .phone-link,
  .mail-link {
    color: $bs-white;

    &:hover {
      color: $bs-white;
    }
  }

  .left {
    .logo-container {
      margin-bottom: 35px;
    }

    .keywords {
      p {
        color: $bs-white;
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding-top: 14px;

    .title {
      margin-bottom: 15px;

      color: $bs-white;

      font-size: 1.6rem;
      font-weight: 600;
    }

    .address-contact {
      display: block;

      margin-bottom: 15px;

      .address {
        margin-bottom: 15px;
      }
    }

    .legal {
      color: rgba($bs-white, .8);

      font-size: 1.6rem;

      .imprint-link {
        color: rgba($bs-white, .8);

        font-size: 1.6rem;

        &:hover {
          color: rgba($bs-white, .8);
        }
      }

      span {
        display: block;
      }

      .delimiter {
        display: none;

        margin: 0 30px;
      }
    }
  }

}

.header,
.footer {
  padding: $section-padding-phone;

  .content-wrapper {
    max-width: $navigation-content-max-width;

    margin: 0 auto;
  }

  .keywords-wrapper {
    margin-bottom: 30px;

    .keywords {
      margin-bottom: 25px;

      p {
        font-family: Officina Serif ITC Pro, serif;
        font-size: 2.4rem;
        font-weight: 600;
      }
    }

    .partners {
      .partner {
        margin-bottom: 25px;

        &:last-child {
          margin-bottom: 0;
        }

        .partner-link {
          display: block;
          text-decoration: none;
        }

        .partner-image {
          display: block;

        }
      }
    }
  }
}

@include bp(tablet) {
  .footer {
    .left {
      flex: 0 1 auto;

      margin-right: 25px;
    }
  }

  .header,
  .footer {
    padding: $section-padding-tablet;

    .content-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .keywords-wrapper {
      margin-bottom: 0;

      .keywords {
        margin-bottom: 80px;
      }

      .partners {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .partner {
          margin-bottom: 0;
          margin-right: 25px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@include bp(desktop) {
  .header {

    .logo {
      max-height: $nav-height-desktop;
    }

    .address {
      display: flex;
      flex-direction: row;

      .address-image-wrapper {
        display: block;
      }
    }
  }

  .footer {
    .left {
      flex: 1 1 auto;
    }

    .right {
      flex: 1 1 auto;

      .row-top {
        display: flex;
        flex-direction: row;
      }

      .address-contact {
        display: flex;
        flex-direction: row;
      }

      .legal {
        span,
        .delimiter {
          display: inline;
        }
      }
    }
  }

  .header,
  .footer {
    padding: $section-padding-desktop $section-padding-tablet $section-padding-tablet;

    .keywords-wrapper {
      .keywords {
        p {
          margin-bottom: 6px;
        }
      }
    }

    .address-contact {
      margin-right: 40px;
    }

  }
}