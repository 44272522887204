.featured_employee {
  display: flex;
  flex-direction: column;

  max-width: 100%;
  height: 100%;

  &:nth-child(n+1) {
    margin-top: 50px;
  }

  .feature_employee_info {
    margin-bottom: 40px;
    text-align: center;

    .employee-portrait-image {
      display: block;

      width: 150px;
      height: 150px;
      margin: 0 auto;
    }

    .name {
      margin: 10px 0 5px;

      font-size: 2rem;
      font-weight: $font-weight-semibold;
    }

    .email {
      display: block;

      margin-bottom: 20px;

      font-weight: $font-weight-regular;
    }

    .xing-link {
      display: inline-block;
      width: auto;

      border-radius: 8px;

      @include bp(tablets-landscape) {
        padding: 12px 12px 12px 48px;
        background: $bs-white url("/static/img/xing-icon-2x.png") no-repeat center left 18px;
        background-size: 22px 25px;
      }
    }

    .linkedin-link {
      @extend .xing-link;

      @include bp(tablets-landscape) {
        background-image: url("/static/img/linkedin-icon-2x.png");
        margin-left: 8px;
      }
    }
  }

  .featured_employee_details {
    flex: 1 0 auto;

    padding: 26px 30px;

    border-top: 4px solid $bs-red;

    ul {
      padding-left: 0 !important;

      li {
        list-style: none !important;
        margin-bottom: 10px;
      }
    }

    .details-header {
      margin-bottom: 34px;

      color: $bs-red;

      font-weight: $font-weight-semibold;
    }

    .details-subtitle {
      font-weight: $font-weight-semibold;
    }

    .details-content {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .detail-columns {
      display: grid;
      gap: 16px;

      @include bp(tablets-landscape) {
        grid-auto-flow: row;
        grid-template-columns: repeat(3, 1fr);
      }
    }

    .activities {
      margin-top: 48px;

      .activities_header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .activities_header-button {
          background-image: url('/static/img/button-expand.svg');
          width: 32px !important;
          height: 32px !important;
          background-size: contain;
          background-repeat: no-repeat;

          &::before {
            content: '';
            display: block;
          }
        }
      }

      &.open {
        .activities_header-button {
          background-image: url('/static/img/button-collapse.svg');

        }
      }
    }
  }
}

.collapsable {
  &-header {
    cursor: pointer;
    padding: 8px 16px;
    margin: 0 -16px;
    transition: background-color 0.25s ease-in-out;
    border-radius: 8px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .collapsable-content {
    display: none;
  }

  &.open {
    .collapsable-content {
      display: block;
    }
  }
}

@include bp(tablet) {
  .featured_employee {
    .feature_employee_info {
      display: flex;
      flex-direction: row;
      text-align: left;

      .employee-portrait-image {
        margin-left: 0;
        margin-right: 30px;
      }
    }
  }
}