@use "sass:math";

.segment-control {
  display: inline-block;

  position: relative;

  width: 100%;
  height: $segment-control-height;

  border: 1px solid $segment-control-border-color;

  font-style: normal;
  font-weight: normal;
  text-decoration: none;


  background: $segment-control-background-color;

  border-radius: $segment-control-border-radius;

  box-sizing: border-box;

  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  transition-duration: $segment-control-transition-duration;
  transition-timing-function: $segment-control-transition-timing-function;

  overflow: hidden;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  font-size: $segment-font-size;

  @if ($segment-dropshadow-active) {
    box-shadow: $segment-dropshadow;
  }

  >input {
    position: absolute;
    left: -10000px;
    opacity: 0;

    &[type='checkbox']~label {
      transition-duration: 0;

      &:before {
        opacity: 0;
      }
    }

    @for $i from 1 through $segment-control-item-count {
      &:disabled:nth-child(#{$i})~label:nth-of-type(#{$i}) {
        opacity: 0.3;
        cursor: not-allowed;
      }

      &:nth-child(#{$i}):checked {
        ~label:nth-of-type(#{$i}) {

          &:after,
          &:before {
            opacity: 1;
          }
        }

        @for $j from $i through $segment-control-item-count {
          ~label:first-of-type:nth-last-of-type(#{$j}) {

            &:after,
            &:before,
            &~label:after,
            &~label:before {
              left: percentage(math.div((math.div(($i - 1), 10)), math.div($j, 10)));
            }
          }
        }
      }
    }
  }

  >label {
    display: inline-block;
    padding: 0 .71em;
    cursor: pointer;
    float: left;
    line-height: $segment-control-height;
    font-style: inherit;
    font-weight: inherit;
    text-decoration: inherit;
    transition-property: none;
    transition-duration: inherit;
    transition-timing-function: inherit;

    &:hover {
      transition-property: background-color;
      background-color: $segment-control-item-hover-color;
      transition-duration: $segment-control-transition-duration;
      transition-timing-function: $segment-control-transition-timing-function;
    }

    &:before,
    &:after {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }

    &:after {
      color: auto-text-color($primary-color-1);
      content: attr(data-value);
      text-align: center;
      padding: inherit;
      z-index: 10;
      font-style: inherit;
      text-decoration: inherit;
      font-weight: inherit;
      opacity: 0;
      transition-property: opacity, left;
      transition-duration: inherit;
      transition-timing-function: inherit;
    }

    &:after,
    & {
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      box-sizing: border-box;
    }

    &:before {
      content: '';
      color: inherit;
      background: $primary-color-1;
      transition-property: left;
      transition-duration: inherit;
      transition-timing-function: inherit;
    }

    @for $i from 1 through $segment-control-item-count {
      &:first-of-type:nth-last-of-type(#{$i}) {

        &,
        &~label {

          &,
          &:after,
          &:before {
            width: percentage(math.div(1, $i));
          }
        }
      }
    }
  }
}