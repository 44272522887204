@import 'colors';

$font-weight-light: 300 !default;
$font-weight-regular: 400 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-extrabold: 800 !default;

$typo-p-size-phone: 1.6rem;
$typo-p-size-tablet: 1.6rem;
$typo-p-size-desktop: 1.8rem;
$typo-p-font-line-height-phone: 1.3;
$typo-p-font-line-height-tablet: 1.3;
$typo-p-font-line-height-desktop: 1.3;
$typo-p-font-weight: $font-weight-light;
$typo-p-font-color: $bs-black;
$typo-p-letter-spacing: .8px;

$typo-lead-letter-spacing: .8px;

$typo-h1-font-color: $bs-black;
$typo-h1-font-weight: $font-weight-bold;
$typo-h1-size-desktop: 3.6rem;

$typo-h2-size-phone: 1.8rem;
$typo-h2-size-tablet: 1.8rem;
$typo-h2-size-desktop: 2.0rem;
$typo-h2-font-line-height-phone: 1.3;
$typo-h2-font-line-height-tablet: 1.3;
$typo-h2-font-line-height-desktop: 1.3;
$typo-h2-font-weight: $font-weight-semibold;
$typo-h2-font-color: $bs-black;