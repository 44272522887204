@mixin bp($point) {

  @if $point == phone {
    @media #{$bp-phones} {
      @content;
    }
  } @else if ($point == phone-max) {
    @media #{$bp-phones-max} {
      @content;
    }
  } @else if $point == tablet {
    @media #{$bp-tablets} {
      @content;
    }
  } @else if $point == tablets-landscape {
    @media #{$bp-tablets-landscape} {
      @content;
    }
  } @else if $point == portrait {
    @media #{$bp-portrait} {
      @content;
    }
  } @else if $point == landscape {
    @media #{$bp-landscape} {
      @content;
    }
  } @else if $point == desktop {
    @media #{$bp-desktop}  {
      @content;
    }
  }

}
