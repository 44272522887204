$segment-control-item-count: 6 !default;
$segment-control-transition-timing-function: cubic-bezier(0.445, 0.050, 0.550, 0.950) !default;
$segment-control-transition-duration: .4s !default;
$segment-control-border-radius: 6px !default;
$segment-control-border-color: transparent !default;
$segment-control-background-color: $grey-3 !default;
$segment-control-item-hover-color: $grey-4 !default;
$segment-control-height: 54px !default;
$segment-dropshadow-active: true !default;
$segment-dropshadow: 0 2px 2px 0 $grey-4 !default;
$segment-font-size: 1.8rem !default;
