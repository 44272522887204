.top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  height: $nav-height;
  padding: 0 14px;

  background-color: $nav-background-color;

  box-shadow: $nav-box-shadow;

  z-index: $z-index-navigation;

  .main {
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    max-width: $navigation-content-max-width;
    height: 100%;

    .logo {
      max-height: $nav-height;
    }

    .button--navigation-menu {
      flex: 0 0 auto;

      font-size: $nav-font-size-button;
      line-height: $nav-line-height;

      cursor: pointer;
    }

    .navigation {
      display: none;

      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      background-color: $primary-color-1;

      .button--navigation-menu-close {
        display: inline;

        position: absolute;
        top: 25px;
        right: 25px;

        color: $grey-1;

        cursor: pointer;
      }

      .navigation-items {
        display: flex;
        flex-direction: column;
        justify-content: center;

        height: 100%;

        .menu-item {
          display: block;

          padding: 15px 0;

          color: $grey-1;

          font-size: $nav-font-size-mobile;
          font-weight: $font-weight-regular;
          text-align: center;
          text-decoration: none;
        }
      }
    }
  }
}

@include bp(tablet) {
  .top {
    padding-left: $section-padding-tablet;
    padding-right: $section-padding-tablet;
  }
}

@include bp(desktop) {
  .top {
    height: $nav-height-desktop;

    padding: 0;

    .main {
      justify-content: space-between;

      @if $nav-centered {
        &::after {
          content: '';
          width: $image-width-logo;
        }
      }

      .button--navigation-menu {
        display: none;
      }

      .navigation {
        display: block;

        position: static;

        background-color: $nav-background-color;

        .button--navigation-menu-close {
          display: none;
        }

        .navigation-items {
          display: flex;
          flex-direction: row;

          border-spacing: 14px 0;

          a:-moz-focusring {
            outline: none;
          }

          a.menu-item {
            display: flex;
            align-items: center;
            justify-content: center;

            width: auto;
            min-width: $nav-height-desktop;
            height: $nav-height-desktop;
            padding: 0 $nav-side-padding-desktop;
            margin: 0 $nav-side-margin-desktop;

            border-bottom: 5px solid $grey-1;

            color: $grey-7;

            font-size: $nav-font-size-desktop;
            text-decoration: none;
            text-align: center;

            &:hover {
              border-bottom: 5px solid lighten($primary-color-1, 5%);
            }

            &:active {
              border-bottom: 5px solid $grey-5;
            }

            &.active {
              border-bottom: 5px solid $primary-color-1;
            }
          }
        }
      }
    }
  }
}

.navigation-open {
  .top {
    .main {
      .navigation {
        display: block;
      }
    }
  }
}