@mixin button-generator($button-name, $number, $background-color, $border-color, $padding-top, $padding-side) {
  #{$button-name} {
    display: inline-block;

    padding: $padding-top $padding-side;

    background-color: $background-color;

    border: 1px solid $border-color;
    border-radius: $button-radius;

    color: auto-text-color($background-color);

    font-size: $button-font-size;

    outline: none;

    @if ($button-dropshadow-active) {
      box-shadow: $button-dropshadow;
    }

    i {
      display: inline;

      padding-right: 8px;
    }

    &:-moz-focusring {
      outline: none;
    }

    &:hover {
      transition-timing-function: $button-transition-function;
      transition-duration: $button-transition-duration;

      @if ($button-dropshadow-active) {
        box-shadow: $button-dropshadow-hover;
      }

      @if ($button-transition-changes-darkens) {
        background-color: darken($color: $background-color, $amount: $button-color-change-amount);
        border: 1px solid darken($color: $border-color, $amount: $button-color-change-amount);
      } @else {
        background-color: lighten($color: $background-color, $amount: $button-color-change-amount);
        border: 1px solid lighten($color: $border-color, $amount: $button-color-change-amount);
      }
    }

    &:active {
      transition-timing-function: $button-transition-function;
      transition-duration: $button-transition-duration;

      @if ($button-dropshadow-active) {
        box-shadow: $button-dropshadow-active;
      }

      @if ($button-transition-changes-darkens) {
        background-color: darken($color: $background-color, $amount: $button-color-change-amount * 2);
        border: 1px solid darken($color: $border-color, $amount: $button-color-change-amount * 2);
      } @else {
        background-color: lighten($color: $background-color, $amount: $button-color-change-amount * 2);
        border: 1px solid lighten($color: $border-color, $amount: $button-color-change-amount * 2);
      }
    }

    &[disabled] {
      background-color: $grey-4;
      color: $grey-5;
    }
  }
}

@for $number from 1 through $primary-color-count {
  @include button-generator(".button-primary-#{$number}", $number, map-get($primary-colors, 'primary-#{$number}'), transparent, $button-padding-top, $button-padding-side);
  @include button-generator(".button-primary-#{$number}-small", $number, map-get($primary-colors, 'primary-#{$number}'), transparent, $button-small-padding-top, $button-small-padding-side);
}

@for $number from 1 through $secondary-color-count {
  $background-color: map-get($secondary-colors, "secondary-#{$number}-fill");
  $border-color: map-get($secondary-colors, "secondary-#{$number}-border");

  @include button-generator(".button-secondary-#{$number}", $number, $background-color, $border-color, $button-padding-top, $button-padding-side);
  @include button-generator(".button-secondary-#{$number}-small", $number, $background-color, $border-color, $button-small-padding-top, $button-small-padding-side);
}
