.address-contact {
  .address-link,
  .phone-link {
    text-decoration: none;
  }

  .address {
    margin-bottom: 15px;
  }

  a[href^=tel] {
    color: inherit;
    text-decoration: none !important;
  }
}

@include bp(tablet) {
  .address-contact {
    display: flex;
    flex-direction: row;

    .address {
      margin-right: 35px;
      margin-bottom: 0;
    }
  }
}